/* eslint-disable prefer-promise-reject-errors */
import React, { useState } from 'react';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Icon } from '@mui/material';
import {
  Button, Form, Input, Alert,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { RestaurantSVG } from '../../../assets/svg/icon';
import { useAuth } from '../../../context/authContext';

const rules = {
  restaurantName: [
    {
      required: true,
      message: 'Please enter the name of restaurant or bar ',
    },
  ],
  userName: [
    {
      required: true,
      message: 'Please input your name',
    },
  ],
  email: [
    {
      required: true,
      message: 'Please input your email address',
    },
    {
      type: 'email',
      message: 'Please enter a validate email!',
    },
  ],
  password: [
    {
      required: true,
      message: 'Please input your password',
    },
  ],
  confirm: [
    {
      required: true,
      message: 'Please confirm your password!',
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject('Passwords do not match!');
      },
    }),
  ],
};

function RegisterAdmin() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [succesFull, setSuccesFull] = useState('');
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const { signup } = useAuth();

  /*  const handleRedirect = (route) => {
    navigate(route);
  }; */

  const handleDataUser = async (data) => {
    setMessage('');
    setSuccesFull('');
    setLoading(true);

    try {
      if (data.password === data.confirm) {
        const res = await signup(data.email, data.password, data);
        if (res) {
          setSuccesFull('Account Created');
          setTimeout(() => {
            setLoading(false);
            navigate('/');
          }, 2500);
        }
      } else {
        setLoading(false);
        setMessage('Error password not matched');
      }
    } catch (error) {
      const messageError = error.message.includes('Email already in use')
        ? 'User Already In Use'
        : 'Error in Register User';
      setMessage(`${messageError}`);
      setLoading(false);
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, marginBottom: 0 }}
        animate={{
          opacity: 1,
          marginBottom: 2,
        }}
      >
        {succesFull !== '' && <Alert type="success" showIcon message={succesFull} />}
        {message !== '' && <Alert type="error" showIcon message={message} />}
      </motion.div>
      <Form form={form} layout="vertical" name="register-form" onFinish={handleDataUser}>
        <Form.Item
          name="restaurantName"
          label="Restaurant or bar name"
          rules={rules.restaurantName}
          hasFeedback
        >
          <Input prefix={<Icon component={RestaurantSVG} style={{ color: '#007bff' }} />} />
        </Form.Item>
        <Form.Item name="userName" label="Name" rules={rules.userName} hasFeedback>
          <Input prefix={<UserOutlined style={{ color: '#007bff' }} />} />
        </Form.Item>
        <Form.Item name="email" label="Email" rules={rules.email} hasFeedback>
          <Input prefix={<MailOutlined style={{ color: '#007bff' }} />} />
        </Form.Item>
        <Form.Item name="password" label="Password" rules={rules.password} hasFeedback>
          <Input.Password prefix={<LockOutlined style={{ color: '#007bff' }} />} />
        </Form.Item>
        <Form.Item name="confirm" label="ConfirmPassword" rules={rules.confirm} hasFeedback>
          <Input.Password prefix={<LockOutlined style={{ color: '#007bff' }} />} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Sign Up
          </Button>
        </Form.Item>
      </Form>
      <p
        style={{
          color: '#6c757d',
          opacity: 0.7,
          fontSize: '12px',
          fontWeight: 'normal',
          textAlign: 'center',
        }}
      >
        <a href="/">If you already have an account? </a>
      </p>
    </>
  );
}
export default RegisterAdmin;
