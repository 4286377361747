/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Card, Row, Col } from 'antd';
import RegisterAdmin from './RegisterAdmin';
import BackgroundImg from '../../../assets/images/login/img-17.jpg';
import Powered from '../../../assets/images/powered.png';

function RegisterOne(props) {
  return (
    <div
      className="h-10"
      style={{
        backgroundImage: `url(${BackgroundImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: 'auto',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          alignItems: 'stretch',
          height: 'auto',
        }}
      >
        <Row justify="center">
          <Col xs={20} sm={20} md={20} lg={7}>
            <Card>
              <div className="my-2">
                <div style={{ textAlign: 'center' }}>
                  <img className="img-fluid" src={`${`${Powered}`}`} alt="" />
                  <p
                    style={{
                      color: '#6c757d',
                      opacity: 0.7,
                      fontSize: '16px',
                      fontWeight: 'normal',
                    }}
                  >
                    Create a new account:
                  </p>
                </div>
                <Row justify="center">
                  <Col xs={24} sm={24} md={20} lg={20}>
                    <RegisterAdmin {...props} />
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default RegisterOne;
