/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable radix */
import React, { useState, useMemo, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Tabs, Form, Button, message, Input, Row, Col, Card } from 'antd';
import Flex from '../../../../components/shared-components/Flex';
import { PageHeaderAlt } from '../../../../components/layout-components/PageHeaderAlt';
import { createItemCustom, updateItem, getItemsByConditionGuest } from '../../../../api/api';

const rules = {
  instagram: [
    {
      required: false,
    },
    {
      validator: (_, value) => {
        if (!value || /^(ftp|http|https):\/\/[^ "]+$/.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Please enter a valid URL'));
      },
    },
  ],
  whatsapp: [
    {
      required: false,
    },
    {
      validator: (_, value) => {
        if (!value || /^(ftp|http|https):\/\/[^ "]+$/.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Please enter a valid URL'));
      },
    },
  ],
  telegram: [
    {
      required: false,
    },
    {
      validator: (_, value) => {
        if (!value || /^(ftp|http|https):\/\/[^ "]+$/.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Please enter a valid URL'));
      },
    },
  ],
  facebook: [
    {
      required: false,
    },
    {
      validator: (_, value) => {
        if (!value || /^(ftp|http|https):\/\/[^ "]+$/.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Please enter a valid URL'));
      },
    },
  ],
};

function MenuLinks() {
  const paramIdRestaurant = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [data, setData] = useState();
  const [id, setId] = useState();
  const [instagram, setInstagram] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [telegram, setTelegram] = useState('');
  const [facebook, setFacebook] = useState('');
  const [twitter, setTwitter] = useState('');
  const [complaint, setComplaint] = useState('');
  const [review, setReview] = useState('');
  const [titleFooter, setTitleFooter] = useState('');
  const [textFooter, setTextFooter] = useState('');
  const [themeInputs, setThemeInputs] = useState();
  const [themeCell, setThemeCell] = useState();
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [additionalLinks, setAdditionalLinks] = useState([]);
  const [newLink, setNewLink] = useState({ title: '', link: '' });
  const { headerNavColor, currentTheme } = useSelector((state) => state.theme);
  const isDarkTheme = currentTheme === 'dark';

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const response = await getItemsByConditionGuest(
          paramIdRestaurant?.id,
          'linksRestaurant',
          'restaurantId',
        );
        const [dataLinksUser] = response;

        if (dataLinksUser.footerPage) {
          setTitleFooter(dataLinksUser.footerPage.titleFooter);
          setTextFooter(dataLinksUser.footerPage.textFooter);
          form.setFieldsValue({
            titleFooter: dataLinksUser.footerPage.titleFooter,
            textFooter: dataLinksUser.footerPage.textFooter,
          });
        }

        const idLinks = dataLinksUser.id;
        const [links] = dataLinksUser.links;
        if (links) {
          setData(links);
          setId(idLinks);
          setInstagram(links?.instagram || '');
          setWhatsapp(links?.whatsapp || '');
          setTelegram(links?.telegram || '');
          setFacebook(links?.facebook || '');
          setTwitter(links?.twitter || '');
          setComplaint(links?.complaint || '');
          setReview(links?.review || '');

          form.setFieldsValue({
            instagram: links?.instagram || '',
            whatsapp: links?.whatsapp || '',
            telegram: links?.telegram || '',
            facebook: links?.facebook || '',
            twitter: links?.twitter || '',
            complaint: links.complaint || '',
            review: links.review || '',
          });

          if (dataLinksUser?.alterLinks.length !== 0) {
            setAdditionalLinks([...dataLinksUser.alterLinks]);
          } else {
            setAdditionalLinks([]);
          }

          return;
        }
        return setData(null);
      } catch (error) {
        console.log(error);
      }
    };
    fetchLinks();
  }, [paramIdRestaurant?.id, form]);

  const colorText = useMemo(() => {
    if (isDarkTheme) {
      setThemeInputs('#1b2531');
      setThemeCell('rgb(0 0 0)');
      return '#fff';
    }
    setThemeCell('rgb(148 152 163)');
    setThemeInputs('#fafafb');
    return '#455560';
  }, [isDarkTheme]);

  const onFinish = async () => {
    try {
      setSubmitLoading(true);

      const mainLinks = {
        instagram,
        whatsapp,
        telegram,
        facebook,
        twitter,
        complaint,
        review,
      };

      const filteredAdditionalLinks = additionalLinks.filter(
        (link) => link.title !== '' && link.link !== '',
      );

      const obj = {
        restaurantId: paramIdRestaurant?.id,
        links: [mainLinks],
        alterLinks: filteredAdditionalLinks,
        footerPage: {
          titleFooter,
          textFooter,
        },
      };

      if (data) {
        await updateItem(id, obj, 'linksRestaurant');
        message.success('Links Updated');
      } else {
        await createItemCustom(obj, 'linksRestaurant');
        message.success('Links Created');
      }

      setSubmitLoading(false);
      navigate('/menu');
    } catch (e) {
      setSubmitLoading(false);
      message.error('Error In Item Created');
    }
  };

  const onBack = () => {
    navigate(-1);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      name="advanced_search"
      className="ant-advanced-search-form"
      initialValues={{
        instagram,
        whatsapp,
        telegram,
        facebook,
        twitter,
        complaint,
        review,
      }}
    >
      <PageHeaderAlt className="border-bottom" overlap>
        <div className="container">
          <Flex
            className="py-2"
            mobileFlex={false}
            justifyContent="space-between"
            alignItems="center"
          >
            <h2 className="mb-3" style={{ color: colorText }}>
              Manage restaurant Links
              {' '}
            </h2>
            <div className="mb-3">
              <Button className="mr-2" onClick={() => onBack()}>
                Discard
              </Button>
              <Button
                type="primary"
                onClick={() => onFinish()}
                htmlType="submit"
                loading={submitLoading}
              >
                SAVE
              </Button>
            </div>
          </Flex>
        </div>
      </PageHeaderAlt>
      <div className="container" style={{ marginTop: '11rem' }}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12}>
            <Card title="Restaurant Main Links">
              <Form.Item name="instagram" label="Instagram" rules={rules.instagram}>
                <Input
                  placeholder="https://www.instagram.com/username"
                  onChange={(e) => setInstagram(e?.target?.value)}
                  value={instagram}
                />
              </Form.Item>
              <Form.Item name="whatsapp" label="WhatsApp" rules={rules.whatsapp}>
                <Input
                  placeholder="https://wa.me/1234567890"
                  onChange={(e) => setWhatsapp(e?.target?.value)}
                  value={whatsapp}
                />
              </Form.Item>
              <Form.Item name="telegram" label="Telegram" rules={rules.telegram}>
                <Input
                  placeholder="https://t.me/username"
                  onChange={(e) => setTelegram(e?.target?.value)}
                  value={telegram}
                />
              </Form.Item>
              <Form.Item name="facebook" label="Facebook" rules={rules.facebook}>
                <Input
                  placeholder="https://www.facebook.com/username"
                  onChange={(e) => setFacebook(e?.target?.value)}
                  value={facebook}
                />
              </Form.Item>
              <Form.Item name="twitter" label="X" rules={rules.facebook}>
                <Input
                  placeholder="https://x.com/username"
                  onChange={(e) => setTwitter(e?.target?.value)}
                  value={twitter}
                />
              </Form.Item>
              <h4 className="mb-3" style={{ color: colorText }}>
                Restaurant Review Links
                {' '}
              </h4>
              <Form.Item name="complaint" label="Link Complaint">
                <Input
                  placeholder="Enter link"
                  onChange={(e) => setComplaint(e?.target?.value)}
                  value={complaint}
                />
              </Form.Item>
              <Form.Item name="review" label="Satisfactory Review Link">
                <Input
                  placeholder="Enter link"
                  onChange={(e) => setReview(e?.target?.value)}
                  value={review}
                />
              </Form.Item>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Card title="Custom Links">
              {additionalLinks.map((link, index) => (
                <div key={index}>
                  <Form.Item label="Title">
                    <Input
                      placeholder="Enter title"
                      value={link.title}
                      onChange={(e) => {
                        const updatedLinks = [...additionalLinks];
                        updatedLinks[index].title = e.target.value;
                        setAdditionalLinks(updatedLinks);
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Link">
                    <Input
                      placeholder="Enter link"
                      value={link.link}
                      onChange={(e) => {
                        const updatedLinks = [...additionalLinks];
                        updatedLinks[index].link = e.target.value;
                        setAdditionalLinks(updatedLinks);
                      }}
                    />
                  </Form.Item>
                </div>
              ))}

              <Form.Item label="Title">
                <Input
                  placeholder="Enter title"
                  onChange={(e) => setNewLink({ ...newLink, title: e.target.value })}
                  value={newLink.title}
                />
              </Form.Item>
              <Form.Item label="Link">
                <Input
                  placeholder="Enter link"
                  onChange={(e) => setNewLink({ ...newLink, link: e.target.value })}
                  value={newLink.link}
                />
              </Form.Item>
              <Button
                onClick={() => {
                  setAdditionalLinks([...additionalLinks, { ...newLink }]);
                  setNewLink({ title: '', link: '' });
                }}
              >
                Add Link
              </Button>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Card title="Footer in Menu">
              <Form.Item name="titleFooter" label="Title Footer">
                <Input
                  placeholder="Enter title"
                  onChange={(e) => setTitleFooter(e?.target?.value)}
                  value={titleFooter}
                />
              </Form.Item>
              <Form.Item name="textFooter" label="Text">
                <Input.TextArea
                  defaultValue={textFooter}
                  rows={4}
                  onChange={(e) => setTextFooter(e.target.value)}
                />
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </div>
    </Form>
  );
}

export default MenuLinks;
