/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Card, Row, Col } from 'antd';
import LoginAdmin from './LoginAdmin';
import BackgroundImg from '../../../assets/images/login/img-17.jpg';
import Powered from '../../../assets/images/powered.png';

function LoginOne(props) {
  console.log('login');
  return (
    <div
      className="h-100"
      style={{
        backgroundImage: `url(${BackgroundImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100%',
      }}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        alignItems: 'stretch',
        height: '100%',
      }}
      >
        <Row justify="center">
          <Col xs={20} sm={20} md={20} lg={7}>
            <Card>
              <div className="my-4">
                <div style={{ textAlign: 'center' }}>
                  <img
                    className="img-fluid"
                    src={`${`${Powered}`}`}
                    alt=""
                  />
                  <p style={{
                    color: '#6c757d',
                    opacity: 0.7,
                    fontSize: '16px',
                    fontWeight: 'normal',
                  }}
                  >
                    Don`t have an account yet?
                    {' '}
                    <a href="/register">Sign Up</a>
                  </p>
                </div>
                <Row justify="center">
                  <Col xs={24} sm={24} md={20} lg={20}>
                    <LoginAdmin {...props} />
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default LoginOne;
